<template>
  <div v-frag>
    <v-container class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="openDivisionDialog">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            aria-label="Keresés"
            label="Keresés"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="divisions"
        :search="search"
        :loading="loadingData"
        loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
        no-data-text="Nincsenek megjeleníthető szervezetek!"
        :no-results-text="`A keresett ${search} kifejezés nem található`"
        :items-per-page="25"
      >
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editDivision(item)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span></span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <!-- SZERVEZET SZERKESZTÉSE START -->
    <v-dialog v-model="divisionDialog.isVisible" scrollable persistent max-width="640">
      <v-card>
        <v-card-title class="indigo">
          <span class="white--text">
            {{ divisionDialog.content.id ? $t('updateDivision') : $t('createDivision') }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDivisionDialog" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="divisionDialog.divisionForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="divisionDialog.content.translation.name"
                              :label="$t('labels.name')"
                              :rules="[$store.state.inputRules.requiredField]"
                              :hint="$t('hints.requiredField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="divisionDialog.content.code"
                              :label="$t('labels.code')"
                              :rules="[$store.state.inputRules.codeValidatorUpperCase]"
                              :hint="$t('hints.optionalFieldCodeUpperCase')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="closeDivisionDialog" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveDivison" :disabled="!divisionDialog.divisionForm">
            {{ divisionDialog.content.id ? 'Frissítés' : 'Mentés' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SZERVEZET SZERKESZTÉSE END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'CareerDivisionsPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    this.divisions = await this.loadTableData();
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Név', value: 'translation.name' },
        { text: 'Kód', value: 'code' },
        { text: '', value: 'actions' },
      ],
      divisions: [],
      loadingData: true,
      divisionDialog: {
        isVisible: false,
        divisionForm: false,
        content: {
          id: null,
          code: null,
          fileStorageId: null,
          fileFullPath: null,
          modifiedBy: 1,
          translation: {
            id: null,
            language: 'hu',
            name: null,
          },
        },
      },
      selectfieldOptions: {
        divisions: [],
      },
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'Sikeres bejelentkezés!',
        errorText: 'Sikertelen bejelentkezés!',
        successBtnText: 'Rendben',
        errorBtnText: 'Rendben',
      },
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('showDivisions');
      // console.log('CareerDivisionsPage tabledata: ', response);
      if (response.status === 200) {
        const sortedList = response.data.sort((a, b) => ((a.id > b.id) ? 1 : -1));
        this.selectfieldOptions.divisions = sortedList;
        return sortedList;
      }
      return [];
    },
    editDivision(division) {
      this.divisionDialog.content = division;
      this.divisionDialog.content.fileStorageId = null;
      /* this.divisionDialog.content.id = division.id;
      this.divisionDialog.content.code = division.code;
      this.divisionDialog.content.fileFullPath = division.fileFullPath;
      this.divisionDialog.content.translation = division.translation; */
      this.divisionDialog.divisionForm = true;
      this.openDivisionDialog();
    },
    async saveDivison() {
      this.openLARDialog();
      if (!this.divisionDialog.content.code || this.divisionDialog.content.code.length < 1) {
        await this.convertDivisionCode(this.divisionDialog.content.translation.name);
      }
      let response = null;
      if (this.divisionDialog.content.id) {
        // ha van id, akkor frissítés
        response = await this.$store.dispatch('updateDivision', this.divisionDialog.content);
      } else {
        // ha nincs id, akkor új létrehozása
        response = await this.$store.dispatch('createDivision', this.divisionDialog.content);
      }
      if (response.status === 200) {
        if (this.divisionDialog.id) {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.DIVISION_UPDATED';
        } else {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.DIVISION_CREATED';
        }
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
      } else {
        if (this.divisionDialog.id) {
          this.loadingAndResponseDialog.successText = 'ERROR_MESSAGES.DIVISION_UPDATED';
        } else {
          this.loadingAndResponseDialog.successText = 'ERROR_MESSAGES.DIVISION_CREATED';
        }
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    async convertDivisionCode(stringToConvert) {
      this.divisionDialog.content.code = await this.$store.dispatch('convertToSlug', stringToConvert);
    },
    openDivisionDialog() {
      this.divisionDialog.isVisible = true;
    },
    closeDivisionDialog() {
      this.divisionDialog = {
        isVisible: false,
        divisionForm: false,
        content: {
          id: null,
          code: null,
          fileStorageId: null,
          fileFullPath: null,
          modifiedBy: 1,
          translation: {
            id: null,
            language: 'hu',
            name: null,
          },
        },
      };
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
      window.location.reload();
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
    },
  },
};
</script>

<style scoped>

</style>
