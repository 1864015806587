<template>
  <div v-frag>
    <v-container fluid class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="$router.push({ name: 'pageBuilder-editor', params: { pageId: 'uj-oldal' }})">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            aria-label="Keresés"
            label="Keresés"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="pages"
        :search="search"
        :loading="loadingData"
        loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
        no-data-text="Nincsenek szerkeszthető oldalak!"
        :no-results-text="`A keresett ${search} kifejezés nem található`"
        :items-per-page="25"
      >
        <template v-slot:item.isDraft="{item}">
          <v-chip small
                  :color="item.isDraft ? 'warning' : 'success'">
            {{ item.isDraft ? $t('draft') : $t('published') }}
          </v-chip>
        </template>
        <template v-slot:item.lastPublishedDate="{ item }">
          {{ item.lastPublishedDate.substr(0, 10) }}
        </template>
        <template v-slot:item.url="{ item }">
          /{{ item.webPageTranslation[0].url }}
        </template>
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on"
                      @click="copyPage(item.id)">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Oldal duplikálása</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editItem(item.id)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Oldal szerkesztése</span>
          </v-tooltip>
          <v-tooltip top v-if="item.isErasable">
            <template v-slot:activator="{on}">
              <v-icon @click="editItem(item.id)"
                      v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Oldal törlése</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on"
                      @click="viewPageInBrowser(item.webPageTranslation[0].url)">
                mdi-eye
              </v-icon>
            </template>
            <span>Oldal megtekintése</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <!-- SNACKBAR START -->
    <v-snackbar v-model="copyPageSnack.isVisible"
                :timeout="copyPageSnack.timeout"
                :color="copyPageSnack.isSuccess ? 'success' : 'error'">
      {{ $t(copyPageSnack.message) }}
    </v-snackbar>
    <!-- SNACKBAR END -->
  </div>
</template>

<script>
export default {
  name: 'PageBuilderHome',
  components: {
    //
  },
  props: {
    //
  },
  async mounted() {
    this.pages = await this.loadTableData();
    this.$store.commit('deleteAvailablePages');
    if (this.$store.state.general.availablePages.length === 0) {
      await this.fillAvailablePages();
      await this.addActualJobsToAvailablePages();
    }
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Oldal neve', value: 'code' },
        { text: 'Státusz', value: 'isDraft' },
        { text: 'Utolsó módosítás', value: 'lastPublishedDate' },
        { text: 'Hivatkozás', value: 'url' },
        { text: 'Utoljára módosította', value: 'userName' },
        { text: '', value: 'actions' },
      ],
      pages: [],
      loadingData: true,
      copyPageSnack: {
        isVisible: false,
        isSuccess: true,
        message: 'SUCCESS_MESSAGES.PAGE_COPY',
        timeout: 2000,
      },
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('showPages');
      // console.log('PageBuilderHome response: ', response);
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    editItem(pageId) {
      this.$router.push({ name: 'pageBuilder-editor', params: { pageId } });
    },
    async copyPage(pageId) {
      const response = await this.$store.dispatch('copyPage', pageId);
      // console.log('copyPage: ', response);
      if (response.status === 200) {
        window.location.reload();
      } else {
        this.copyPageSnack.message = 'ERROR_MESSAGES.PAGE_COPY';
        this.copyPageSnack.isSuccess = false;
      }
      this.copyPageSnack.isVisible = true;
    },
    viewPageInBrowser(url) {
      window.open(`/${url}`, '_blank');
    },
    async fillAvailablePages() {
      this.pages.forEach((item) => {
        if (!item.isDraft) {
          this.$store.commit('setAvailablePages',
            {
              name: `${item.webPageTranslation[0].name}`,
              url: `/${item.webPageTranslation[0].url}`,
              group: 'Oldal',
            });
        }
      });
    },
    async addActualJobsToAvailablePages() {
      const response = await this.$store.dispatch('showActualJobs');
      if (response.status === 200) {
        response.data.forEach((item) => {
          this.$store.commit('setAvailablePages',
            {
              name: `${item.translation.title}`,
              url: `/${item.translation.url}`,
              group: 'Állásajánlat',
            });
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
