<template>
  <div id="hw">
    <template v-for="(item, index) in pageContent">
      <component :is="item.elementName" :element-props="item.elementProperties" :key="index"></component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    await this.GetPageContent('home');
  },
  data() {
    return {
      pageContent: [],
    };
  },
  methods: {
    async GetPageContent(url) {
      const response = await this.$store.dispatch('showPageByUrl', url);
      if (response.status === 200) {
        this.pageContent = JSON.parse(response.data.contentJson);
      } else {
        this.$router.push({ name: '404-oldal' });
      }
    },
  },
};
</script>

<style scoped>

</style>
