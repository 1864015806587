<template>
  <tracking-login-form />
</template>

<script>
import TrackingLoginForm from '@/components/basicElements/forms/TrackingLoginForm.vue';

export default {
  name: 'TrackingLoginPage',
  components: {
    TrackingLoginForm,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
