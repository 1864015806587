<template>
  <v-container>
    <v-btn color="primary" @click="callSaveNewBasicElement">
      Kattint
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'MasterDataPageBasicElements',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      basicElement: {
        id: null,
        code: 'image-gallery-5',
        contentJson: {
          displayName: 'Image Gallery 1',
          elementName: 'image-gallery-1',
          elementProperties: {
            isContainerFluid: false,
            image1: 'images/dummy/city.jpg',
            image2: 'images/dummy/lake.jpg',
            image3: 'images/dummy/dessert.jpg',
            image4: 'images/dummy/wheat.jpg',
          },
        },
        isDraft: 0,
        lastPublishedDate: '2021-02-12',
        basicElementTranslation: [
          {
            id: 0,
            language: 'hu',
            name: 'Image Gallery 1',
            description: 'Image Gallery 1',
          },
        ],
      },
    };
  },
  methods: {
    async callSaveNewBasicElement() {
      const content = JSON.stringify(this.basicElement.contentJson);
      const send = {
        id: null,
        code: 'image-gallery-5',
        contentJson: content,
        isDraft: 0,
        lastPublishedDate: '2021-02-12',
        basicElementTranslation: [
          {
            id: 0,
            language: 'hu',
            name: 'Image Gallery 1',
            description: 'Image Gallery 1',
          },
        ],
      };
      const response = await this.$store.dispatch('saveNewBasicElement', send);
      // console.log('callSaveNewBasicElement: ', response);
    },
  },
};
</script>

<style scoped>

</style>
