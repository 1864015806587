<template>
  <div v-frag>
    <v-container class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="$router.push({ name: 'menuBuilder-editor', params: { menuId: 'uj-menu' }})">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            aria-label="Keresés"
            label="Keresés"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="menus"
        :search="search"
        :loading="loadingData"
        loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
        no-data-text="Nincsenek szerkeszthető menük!"
        :no-results-text="`A keresett ${search} kifejezés nem található`"
        :items-per-page="25"
      >
        <template v-slot:item.isDefault="{item}">
          <v-chip small
                  color="success"
                  v-if="item.isDefault === 1">
            {{ $t('default') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editItem(item.id)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Menü szerkesztése</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'MenuBuilderHome',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    this.menus = await this.loadTableData();
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Név', value: 'translation.name' },
        { text: 'Státusz', value: 'isDefault' },
        { text: '', value: 'actions' },
      ],
      menus: [],
      loadingData: true,
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('showAllMenus');
      // console.log('MenuBuilderHome response: ', response);
      if (response.status === 200) {
        return response.data.sort((a, b) => ((a.validFrom < b.validFrom) ? 1 : -1));
      }
      return [];
    },
    editItem(menuId) {
      this.$router.push({ name: 'menuBuilder-editor', params: { menuId } });
    },
  },
};
</script>

<style scoped>

</style>
