<template>
  <div v-frag>
    <!-- PAGE TOOLBAR START -->
    <v-app-bar color="indigo darken-2" dark tile>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-icon @click="$router.push({name: 'admin-career-jobs'})">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('backToAllJobs') }}</span>
      </v-tooltip>
      <v-toolbar-title>
        <b>{{ settings.translation.title }}</b> - szerkesztése
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="popupSettings.isVisible = true;">
            <v-icon>
              mdi-cog
            </v-icon>
          </v-btn>
        </template>
        <span>Álláshirdetés beállításai</span>
      </v-tooltip>
      <!--<v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="pagePreview">
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
        <span>Oldal megtekintése</span>
      </v-tooltip>-->
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn v-show="status === 'NEW'" icon v-on="on" @click="createJob">
            <v-icon>
              mdi-file
            </v-icon>
          </v-btn>
        </template>
        <span>Álláshirdetés mentése</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" v-show="status === 'PUBLISHED'" @click="updateJob">
            <v-icon>
              mdi-cloud-refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Frissítés</span>
      </v-tooltip>
    </v-app-bar>
    <!-- PAGE TOOLBAR END -->
    <!-- PAGE SIDEBAR START -->
    <v-navigation-drawer width="320" mini-variant-width="80" app right permanent :mini-variant="drawerMini">
      <v-toolbar color="teal darken-1" dark flat tile absolute width="100%">
        <v-toolbar-title>
          Page Builder
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="drawerMini = !drawerMini" :class="drawerMini ? 'mr-4' : 'pageBuilder-closeSideNav'">
          <v-icon>
            mdi-backburger
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="mx-0 pt-12 blue-grey darken-2" style="min-height: 100%;" v-if="availableElements.length > 1">
        <draggable class="dragArea list-group elementShop mt-4"
                   v-model="availableElements"
                   :group="{ name: 'builderElements', pull: 'clone', put: 'false' }"
                   :clone="cloneBasicElementToPageContent">
          <template v-for="(element, index) in availableElements">
            <v-col cols="12" :key="index">
              <v-card class="cursor-grab">
                <v-card-title v-if="!drawerMini">
                  <span class="body-2">
                    {{ element.contentConverted.displayName }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{on}">
                      <v-icon @click="openPopupSample(element.contentConverted)" v-on="on">
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>
                      Megtekintés
                    </span>
                  </v-tooltip>
                </v-card-title>
                <v-tooltip left v-else>
                  <template v-slot:activator="{on}">
                    <v-avatar v-on="on">
                      <span>
                        {{ element.contentConverted.displayName.slice(0,1) }}
                        {{ element.contentConverted.displayName.slice(element.contentConverted.displayName.indexOf(' ')+1,element.contentConverted.displayName.indexOf(' ')+2) }}
                        {{ element.contentConverted.displayName.slice(element.contentConverted.displayName.lastIndexOf(' ')+1,element.contentConverted.displayName.lastIndexOf(' ')+2) }}
                      </span>
                    </v-avatar>
                  </template>
                  <span>{{ element.contentConverted.displayName }}</span>
                </v-tooltip>
              </v-card>
            </v-col>
          </template>
        </draggable>
      </v-row>
    </v-navigation-drawer>
    <!-- PAGE SIDEBAR END -->
    <!-- PAGE CONTENT START -->
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <draggable class="dragArea list-group pageBuilder-liveEditor-wrapper"
                     :list="currentPage.content"
                     group="builderElements" animation="200">
            <template v-for="(item, index) in currentPage.content">
              <div class="pageBuilder-liveEditor-box mb-4" :key="index">
                <component :is="item.contentConverted.elementName"
                           :element-props.sync="item.contentConverted.elementProperties"
                           editor-mode
                           v-on:delete-element="deleteItem(index)"
                           v-on:save-element="saveItem(index)">
                </component>
              </div>
            </template>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
    <!-- PAGE CONTENT END -->
    <!-- BUILDER ELEMENT SAMPLE POPUP START -->
    <v-dialog v-model="popup.isVisible">
      <v-card>
        <v-card-title class="indigo">
          <span class="white--text">
            {{ popup.itemToShow.displayName }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="popup.isVisible = !popup.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <component :is="popup.itemToShow.elementName" :element-props="popup.itemToShow.elementProperties"></component>
      </v-card>
    </v-dialog>
    <!-- BUILDER ELEMENT SAMPLE POPUP END-->
    <!-- JOB SETTINGS POPUP START -->
    <v-dialog v-model="popupSettings.isVisible" scrollable persistent transition="dialog-bottom-transition" max-width="640">
      <v-card tile>
        <v-card-title class="indigo">
            <span class="white--text">
              {{ $t('jobSettings') }}
            </span>
          <v-spacer></v-spacer>
          <v-icon @click="popupSettings.isVisible = !popupSettings.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="settingsForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="settings.translation.title"
                              :label="$t('labels.title')"
                              :rules="[$store.state.inputRules.requiredField]"
                              :hint="`Kötelező kitölteni! Közvetlen hivatkozás: ${origin}/${settings.translation.url}`"
                              filled
                              persistent-hint
                              @change="createValidUrl">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="settings.code"
                              :label="$t('labels.code')"
                              :rules="[$store.state.inputRules.codeValidatorUpperCase]"
                              :hint="$t('hints.optionalFieldCodeUpperCase')"
                              persistent-hint
                              filled
                              append-icon="mdi-refresh"
                              @click:append="generateJobCode(settings.translation.title)">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="settings.divisionId"
                          :items="selectFieldOptions.divisions"
                          item-text="translation.name"
                          item-value="id"
                          :label="$t('labels.chooseDivision')"
                          filled>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="settings.validFrom"
                              :label="$t('labels.validFrom')"
                              :rules="inputRules.validDateRule"
                              :hint="$t('hints.requiredFieldDate')"
                              persistent-hint
                              v-mask="$store.state.mask.inputDate.type1"
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="settings.validTo"
                              :label="$t('labels.validTo')"
                              :rules="inputRules.validDateRule"
                              :hint="$t('hints.requiredFieldDate')"
                              persistent-hint
                              v-mask="$store.state.mask.inputDate.type1"
                              filled>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="popupSettings.isVisible = false" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="popupSettings.isVisible = false">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- JOB SETTINGS POPUP END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import dayjs from 'dayjs';

export default {
  name: 'CareerJobBuilderPage',
  components: {
    draggable, LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$store.state.builderElements.basicElements.length === 0) {
      await this.callshowBasicElements();
      this.availableElements = await this.$store.dispatch('convertBasicElemetContent');
    } else {
      this.availableElements = this.$store.state.builderElements.basicElements;
    }
    this.selectFieldOptions.divisions = await this.showAllDivisions();
    if (this.$route.params.jobId === 'uj-allashirdetes') {
      this.setDefaultDates();
      this.popupSettings.isVisible = true;
      this.status = 'NEW';
    } else {
      this.status = 'PUBLISHED';
      this.currentPage.content = await this.getJobInEditor();
    }
  },
  data() {
    return {
      origin: window.location.origin,
      currentPage: {
        content: [],
        url: '',
      },
      pageUrlEditable: false,
      availableElements: [],
      drawerMini: false,
      popup: {
        isVisible: false,
        itemToShow: {},
      },
      popupSettings: {
        isVisible: true,
      },
      settingsForm: false,
      status: 'NEW',
      settings: {
        code: null,
        divisionId: null,
        fileStorageId: null,
        id: null,
        modifiedBy: 1,
        translation: {
          desc: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.',
          id: null,
          jobId: null,
          language: 'hu',
          title: null,
          url: null,
        },
        validFrom: null,
        validTo: null,
      },
      done: '',
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'Bejelentkezés folyamatban.',
        successText: 'Sikeres bejelentkezés!',
        errorText: 'Sikertelen bejelentkezés!',
        successBtnText: 'Rendben',
        errorBtnText: 'Rendben',
      },
      selectFieldOptions: {
        divisions: [],
      },
      inputRules: {
        validDateRule: [
          (value) => !!value || this.$t('hints.requiredFieldDate'),
          (value) => this.dateValidator(value) || this.$t('hints.dateMustBeValid'),
        ],
      },
    };
  },
  methods: {
    async showAllDivisions() {
      const response = await this.$store.dispatch('showDivisions');
      if (response.status === 200) {
        return response.data.sort((a, b) => ((a.translation.name > b.translation.name) ? 1 : -1));
      }
      return [];
    },
    async callshowBasicElements() {
      const response = this.$store.dispatch('showBasicElements');
      return response;
    },
    async getJobInEditor() {
      const response = await this.$store.dispatch('showJobById', this.$route.params.jobId);
      // console.log('CareerJobBuilderPage: ', response);
      if (response.status === 200) {
        const converted = JSON.parse(response.data.contentJson);
        const mapped = converted.map((item) => {
          // console.log('blllbe');
          return {
            contentConverted: item,
          };
        });
        this.settings = response.data;
        return mapped;
      }
      return [];
    },
    cloneBasicElementToPageContent(basicElement) {
      // console.log('CLONE! - ', basicElement);
      return JSON.parse(JSON.stringify(basicElement));
    },
    deleteItem(index) {
      this.currentPage.content.splice(index, 1);
    },
    saveItem(index) {
      // console.log('save this item to db:', index);
    },
    openPopupSample(item) {
      this.popup.itemToShow = item;
      this.popup.isVisible = true;
    },
    async createJob() {
      this.openLARDialog();
      const contentJson = await this.$store.dispatch('convertToJson', this.currentPage.content);
      // console.log('contentJson: ', contentJson);
      this.settings.fileStorageId = null;
      this.settings.contentJson = contentJson;
      const response = await this.$store.dispatch('createJob', this.settings);
      if (response.status === 200) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.JOB_CREATED';
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
      } else {
        this.loadingAndResponseDialog.successText = 'ERROR_MESSAGES.JOB_CREATED';
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    async updateJob() {
      this.openLARDialog();
      const contentJson = await this.$store.dispatch('convertToJson', this.currentPage.content);
      // console.log('contentJson: ', contentJson);
      this.settings.fileStorageId = null;
      this.settings.contentJson = contentJson;
      // console.log('ezt küldöm: ', this.settings);
      const response = await this.$store.dispatch('updateJob', this.settings);
      if (response.status === 200) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.JOB_UPDATED';
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.JOB_UPDATED';
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    pagePreview() {
      this.$router.push(`/${this.pageSettings.webPageTranslation[0].url}`);
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.$router.push({ name: 'admin-career-jobs' });
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    async createValidUrl() {
      this.currentPage.url = await this.$store.dispatch('convertToSlug', this.settings.translation.title);
      this.settings.translation.url = `allasajanlatok/${this.currentPage.url}`;
    },
    async generateJobCode(stringToConvert) {
      this.settings.code = await this.$store.dispatch('convertToUpperCaseCode', stringToConvert);
    },
    setDefaultDates() {
      const validFrom = new Date();
      const validTo = dayjs(validFrom).add(1, 'month');
      this.settings.validFrom = validFrom.toISOString().substr(0, 10);
      this.settings.validTo = validTo.toISOString().substr(0, 10);
    },
    dateValidator(date) {
      const inputDate = new Date(date);
      // console.log('inputDate: ', inputDate);
      if (date) {
        return !!(date.length === 10 && inputDate.getDate());
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>
