<template>
  <v-container class="fill-height">
    <v-row class="fill-height" align="center" align-content="center">
      <v-col cols="12" md="8" offset-md="2" class="text-center mb-12">
        <p class="text-h1 blue--text text--darken-3 mb-12">404</p>
        <p class="text-h3">Lehet, hogy félreütöttél valamit vagy időközben megszűnt a keresett oldal.</p>
        <p class="text-h4">Ellenőrizd az URL címet, vagy válassz a főmenüből a továbblépéshez.</p>
        <v-img height="1px" width="1px" :src="`/page404.png`" style="visibility: hidden!important"></v-img>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="$router.push({ name: 'Home'} )" x-large>
          Vissza a kezdőlapra
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoPage',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
