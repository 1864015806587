<template>
  <v-container fluid class="login-page-bg fill-height">
    <v-container class="fill-height">
      <v-row class="fill-height" justify="center" align="center" align-content="center">
        <v-col>
          <v-row justify="center" align-content="center" align="center">
            <v-col cols="auto">
              <v-img :src="`${publicPath}images/cmsLogo-teli3@300.png`" width="150" height="150"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center" align-content="center" align="center">
            <v-card max-width="400" width="90%" color="transparent" elevation="0">
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="loginForm.email"
                                  :label="$t('email')"
                                  filled
                                  color="grey darken-4"
                                  background-color="white"
                                  hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="loginForm.password"
                                  :label="$t('password')"
                                  :type="showPass ? 'text' : 'password'"
                                  filled
                                  color="grey darken-4"
                                  background-color="white"
                                  :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="showPass = !showPass"
                                  @keyup.enter="login"
                                  hide-details>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-6 px-9">
                <v-btn color="green" dark block @click="login">
                  Bejelentkezés
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
          <v-row justify="center">
            <p class="white--text ca body-2"
               @click="$router.push({ name: 'home' })">
              Vissza a kezdőlapra
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      showPass: false,
    };
  },
  methods: {
    async login() {
      const response = await this.$store.dispatch('Login', this.loginForm);
      if (response.status === 200) {
        await this.$router.push({ name: 'admin-iranyitopult' });
      }
    },
  },
};
</script>

<style scoped>

</style>
