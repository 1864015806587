<template>
  <div v-frag>
    <!-- MENU TOOLBAR START -->
    <v-app-bar color="indigo darken-2" dark tile>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-icon @click="$router.push({name: 'footerBuilder-home'})">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('backToAllMenus') }}</span>
      </v-tooltip>
      <v-toolbar-title>
        <b>{{ settings.translation.name }}</b> - szerkesztése
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="popupSettings.isVisible = true;">
            <v-icon>
              mdi-cog
            </v-icon>
          </v-btn>
        </template>
        <span>Footer beállításai</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn v-show="status === 'NEW'" icon v-on="on" @click="createFooter">
            <v-icon>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </template>
        <span>Footer mentése</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" v-show="status === 'PUBLISHED'" @click="updateFooter">
            <v-icon>
              mdi-cloud-refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Frissítés</span>
      </v-tooltip>
    </v-app-bar>
    <!-- MENU TOOLBAR END -->
    <!-- CONTENT START -->
    <v-container fluid>
      <GlobalFooter :element-props="elementProps"/>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-h5">
          Widget area szerkesztése
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.footerWidgetArea.isVisible"
                    :label="$t('footer.footerWidgetAreaIsVisible')"
                    :items="selectFieldOptions.isVisible"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.footerWidgetArea.fluid"
                    :label="$t('footer.footerWidgetAreaIsFluid')"
                    :items="selectFieldOptions.isVisible"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.footerWidgetArea.layoutType"
                    :label="$t('footer.footerWidgetAreaLayout')"
                    :items="selectFieldOptions.layoutTypes"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field v-model="elementProps.footerWidgetArea.backgroundColor"
                        :label="$t('footer.footerWidgetAreaBackground')"
                        :hint="$t('hints.colorRequired')"
                        persistent-hint
                        filled>
            <template v-slot:append-outer>
              <div @click="openColorPicker(10)"
                   :style="`border: 1px solid #757575; cursor: pointer; width: 24px; height: 24px; background: ${elementProps.footerWidgetArea.backgroundColor}`" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field v-model="elementProps.footerWidgetArea.textColor"
                        :label="$t('footer.footerWidgetAreaTextColor')"
                        :hint="$t('hints.colorRequired')"
                        persistent-hint
                        filled>
            <template v-slot:append-outer>
              <div @click="openColorPicker(11)"
                   :style="`border: 1px solid #757575; cursor: pointer; width: 24px; height: 24px; background: ${elementProps.footerWidgetArea.textColor}`" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.footerWidgetArea.paddingTop"
                    :label="$t('footer.footerWidgetAreaPaddingTop')"
                    :items="selectFieldOptions.padding"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.footerWidgetArea.paddingBottom"
                    :label="$t('footer.footerWidgetAreaPaddingBottom')"
                    :items="selectFieldOptions.padding"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <template v-for="(col, lindex) in elementProps.footerWidgetArea.layoutType">
          <v-col :key="`l${lindex}`"
                 cols="12"
                 sm="6"
                 md="4"
                 class="text-body-2"
                 :lg="col">
            <v-tabs v-model="translationTab"
                    background-color="teal darken-1"
                    dark>
              <v-tab v-for="(item, index) in elementProps.boxes[lindex].translations"
                     :key="`lt-${index}`">
                {{ item.name }}
              </v-tab>
              <v-tabs-items v-model="translationTab">
                <v-tab-item v-for="(item, index) in elementProps.boxes[lindex].translations"
                            :key="`lc-${index}`">
                  <v-card outlined tile>
                    <v-card-text>
                      <div v-html="item.text"></div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn v-if="elementProps.boxes[lindex].translations.length > 1"
                             color="error"
                             text
                             class="mr-4"
                             @click="deleteTranslation(index, lindex)">
                        {{ $t('button.delete') }}
                      </v-btn>
                      <v-btn color="primary"
                             @click="editTranslation(item, index, lindex)">
                        {{ $t('button.edit') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-h5">
          Copyright area szerkesztése
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.copyRightArea.isVisible"
                    :label="$t('footer.footerCopyRightAreaIsVisible')"
                    :items="selectFieldOptions.isVisible"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.copyRightArea.fluid"
                    :label="$t('footer.footerCopyRightAreaIsFluid')"
                    :items="selectFieldOptions.isVisible"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field v-model="elementProps.copyRightArea.backgroundColor"
                        :label="$t('footer.footerCopyrightAreaBackground')"
                        :hint="$t('hints.colorRequired')"
                        persistent-hint
                        filled>
            <template v-slot:append-outer>
              <div @click="openColorPicker(20)"
                   :style="`border: 1px solid #757575; cursor: pointer; width: 24px; height: 24px; background: ${elementProps.copyRightArea.backgroundColor}`" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field v-model="elementProps.copyRightArea.textColor"
                        :label="$t('footer.footerCopyrightAreaTextColor')"
                        :hint="$t('hints.colorRequired')"
                        persistent-hint
                        filled>
            <template v-slot:append-outer>
              <div @click="openColorPicker(21)"
                   :style="`border: 1px solid #757575; cursor: pointer; width: 24px; height: 24px; background: ${elementProps.copyRightArea.textColor}`" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.copyRightArea.textAlign"
                    :label="$t('labels.textAlign')"
                    :items="selectFieldOptions.textAlign"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.copyRightArea.paddingTop"
                    :label="$t('footer.footerWidgetAreaPaddingTop')"
                    :items="selectFieldOptions.padding"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select v-model="elementProps.copyRightArea.paddingBottom"
                    :label="$t('footer.footerWidgetAreaPaddingBottom')"
                    :items="selectFieldOptions.padding"
                    :hint="$t('hints.requiredField')"
                    persistent-hint
                    filled>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-tabs v-model="translationTab"
                background-color="teal darken-1"
                dark>
          <v-tab v-for="(item, index) in elementProps.translations"
                 :key="`lt-${index}`">
            {{ item.name }}
          </v-tab>
          <v-tabs-items v-model="translationTab">
            <v-tab-item v-for="(item, index) in elementProps.translations"
                        :key="`lc-${index}`">
              <v-card outlined tile>
                <v-card-text>
                  <div v-html="item.text"></div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn v-if="elementProps.translations.length > 1"
                         color="error"
                         text
                         class="mr-4"
                         @click="deleteTranslation(index, 'OFF')">
                    {{ $t('button.delete') }}
                  </v-btn>
                  <v-btn color="primary"
                         @click="editTranslation(item, index)">
                    {{ $t('button.edit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-row>
    </v-container>
    <!-- CONTENT END -->
    <!-- MENU SETTINGS POPUP START -->
    <v-dialog v-model="popupSettings.isVisible" scrollable persistent transition="dialog-bottom-transition" max-width="640">
      <v-card tile>
        <v-card-title class="indigo">
            <span class="white--text">
              {{ $t('menuSettings') }}
            </span>
          <v-spacer></v-spacer>
          <v-icon @click="popupSettings.isVisible = !popupSettings.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="settingsForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="settings.translation.name"
                              :label="$t('labels.title')"
                              :rules="[$store.state.inputRules.requiredField]"
                              :hint="$t('hints.requiredField')"
                              filled
                              persistent-hint>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="settings.isDefault"
                          :items="selectFieldOptions.isDefault"
                          :label="$t('labels.isDefaultMenu')"
                          filled>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="popupSettings.isVisible = false" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="popupSettings.isVisible = false" :disabled="!settingsForm">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MENU SETTINGS POPUP END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="cancelColorPicker" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveColorPicker">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import GlobalFooter from '@/components/global/GlobalFooter.vue';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';
import { getWebUser } from '@/utils/jwtHelper.js';

export default {
  name: 'FooterBuilderEditor',
  components: {
    LoadingAndResponseDialog, GlobalFooter, TranslationEditorDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$route.params.footerId === 'uj-footer') {
      this.status = 'NEW';
      this.popupSettings.isVisible = true;
    } else {
      this.status = 'PUBLISHED';
      this.settings = await this.loadFooter(this.$route.params.footerId);
    }
    this.createPaddingItems();
    const wu = getWebUser();
    // console.log(wu);
    if (wu) {
      this.settings.modifiedBy = wu.userId;
    } else {
      this.settings.modifiedBy = 1;
    }
  },
  data() {
    return {
      elementProps: this.$DEFAULT_FOOTER,
      settings: {
        id: null,
        isDefault: 0,
        contentJson: [],
        modifiedBy: 1,
        translation: {
          id: null,
          language: 'hu',
          name: 'Új footer',
        },
      },
      status: 'NEW',
      popupSettings: {
        isVisible: false,
      },
      settingsForm: false,
      selectFieldOptions: {
        isDefault: [
          { text: 'Igen', value: 1 },
          { text: 'Nem', value: 0 },
        ],
        isVisible: [
          { text: 'Igen', value: true },
          { text: 'Nem', value: false },
        ],
        layoutTypes: [
          { text: '1/1', value: [12] },
          { text: '1/2 - 1/2', value: [6,6] },
          { text: '1/3 - 1/3 - 1/3', value: [4,4,4] },
          { text: '1/4 - 1/4 - 1/4 - 1/4', value: [3,3,3,3] },
          { text: '1/3 - 2/3', value: [4,8] },
          { text: '2/3 - 1/3', value: [8,4] },
          { text: '2/3 - 1/6 - 1/6', value: [8,2,2] },
          { text: '1/6 - 2/3 - 1/6', value: [2,8,2] },
          { text: '1/6 - 1/6 - 2/3', value: [2,2,8] },
          { text: '1/2 - 1/4 - 1/4', value: [6,3,3] },
          { text: '1/4 - 1/2 - 1/4', value: [3,6,3] },
          { text: '1/4 - 1/4 - 1/2', value: [3,3,6] },
          { text: '5/12 - 7/12', value: [5,7] },
          { text: '7/12 - 5/12', value: [7,5] },
        ],
        padding: [],
        textAlign: [
          { text: 'Jobbra', value: 'end' },
          { text: 'Középre', value: 'center' },
          { text: 'Balra', value: 'start' },
        ],
      },
      /**
       * type: 10 = widget area bgcolor
       * type: 11 = widget area betűszín
       * type: 20 = copyright bgcolor
       * type: 21 = copyright betűszín
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        type: 10,
        title: 'Szín kiválasztása',
      },
      translationTab: 0,
      visibleElements: ['text1'],
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.FOOTER_CREATED',
        errorText: 'ERROR_MESSAGES.FOOTER_CREATED',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
    };
  },
  methods: {
    async loadFooter(id) {
      const response = await this.$store.dispatch('showFooterById', id);
      if (response.status === 200) {
        this.elementProps = await this.convertJsonToObject(response.data.contentJson);
        return response.data;
      }
      return [];
    },
    async createFooter() {
      this.openLARDialog();
      this.settings.contentJson = JSON.stringify(this.elementProps);
      const response = await this.$store.dispatch('createFooter', this.settings);
      if (response.status === 200 || response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.FOOTER_CREATED';
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.FOOTER_CREATED';
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    async updateFooter() {
      this.openLARDialog();
      this.settings.contentJson = JSON.stringify(this.elementProps);
      const response = await this.$store.dispatch('updateFooter', this.settings);
      if (response.status === 200 || response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.FOOTER_UPDATED';
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.FOOTER_UPDATED';
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    async convertJsonToObject(jsonString) {
      return JSON.parse(jsonString);
    },
    openColorPicker(type) {
      this.popupColorPicker.type = type;
      switch (type) {
        case 10:
          this.popupColorPicker.pickerColor = this.elementProps.footerWidgetArea.backgroundColor;
          break;
        case 11:
          this.popupColorPicker.pickerColor = this.elementProps.footerWidgetArea.textColor;
          break;
        case 20:
          this.popupColorPicker.pickerColor = this.elementProps.copyRightArea.backgroundColor;
          break;
        case 21:
          this.popupColorPicker.pickerColor = this.elementProps.copyRightArea.textColor;
          break;
      }
      this.popupColorPicker.isVisible = true;
    },
    saveColorPicker() {
      switch (this.popupColorPicker.type) {
        case 10:
          this.elementProps.footerWidgetArea.backgroundColor = JSON.parse(JSON.stringify(this.popupColorPicker.pickerColor));
          break;
        case 11:
          this.elementProps.footerWidgetArea.textColor = JSON.parse(JSON.stringify(this.popupColorPicker.pickerColor));
          break;
        case 20:
          this.elementProps.copyRightArea.backgroundColor = JSON.parse(JSON.stringify(this.popupColorPicker.pickerColor));
          break;
        case 21:
          this.elementProps.copyRightArea.textColor = JSON.parse(JSON.stringify(this.popupColorPicker.pickerColor));
          break;
      }
      this.popupColorPicker.isVisible = false;
    },
    cancelColorPicker() {
      this.popupColorPicker.isVisible = false;
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
      this.$router.push({ name: 'footerBuilder-home' });
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
    },
    createPaddingItems() {
      for (let i = 1; i < 17; i++) {
        this.selectFieldOptions.padding.push(
          { text: `${i * 4}px`, value: i }
        )
      }
    },
  },
};
</script>

<style scoped>

</style>
