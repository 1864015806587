<template>
  <div v-frag>
    <!-- PAGE TOOLBAR START -->
    <v-app-bar color="indigo darken-2" dark tile>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-icon @click="$router.push({name: 'pageBuilder-home'})">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Vissza az összes oldalra</span>
      </v-tooltip>
      <v-toolbar-title>
        <b>{{ pageSettings.webPageTranslation[0].name }}</b> - oldal szerkesztése
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="pageSettings.webPageTranslation[0].url !== 'home'">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="popupSettings.isVisible = true;">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <span>Oldal beállításai</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn v-show="pageSettings.status === 'NEW'" icon v-on="on" @click="createPage(1)">
              <v-icon>
                mdi-file
              </v-icon>
            </v-btn>
          </template>
          <span>Piszkozat mentése</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" v-show="pageSettings.status === 'PUBLISHED' || pageSettings.status === 'DRAFT'"
                   @click="createPage(pageSettings.isDraft, true)">
              <v-icon>
                mdi-cloud-refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Frissítés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn v-show="pageSettings.status === 'NEW' || pageSettings.status === 'DRAFT'" icon v-on="on"
                   @click="createPage(0, pageSettings.status !== 'NEW')">
              <v-icon>
                mdi-cloud-upload
              </v-icon>
            </v-btn>
          </template>
          <span>Közzététel</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn v-show="pageSettings.status === 'PUBLISHED'" icon v-on="on" @click="createPage(1, true)">
              <v-icon>
                mdi-cloud-off-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Vázlattá tesz</span>
        </v-tooltip>
      </template>
      <template v-if="pageSettings.webPageTranslation[0].url === 'home'">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" v-show="pageSettings.status === 'PUBLISHED' || pageSettings.status === 'DRAFT'"
                   @click="createPage(pageSettings.isDraft, true)">
              <v-icon>
                mdi-cloud-refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Frissítés</span>
        </v-tooltip>
      </template>
    </v-app-bar>
    <!-- PAGE TOOLBAR END -->
    <!-- PAGE SIDEBAR START -->
    <v-navigation-drawer width="320" mini-variant-width="80" app right permanent :mini-variant="drawerMini">
      <v-toolbar color="teal darken-1" dark flat tile absolute width="100%">
        <v-toolbar-title>
          Page Builder
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="drawerMini = !drawerMini" :class="drawerMini ? 'mr-4' : 'pageBuilder-closeSideNav'">
          <v-icon>
            mdi-backburger
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="mx-0 pt-12 blue-grey darken-2" style="min-height: 100%;" v-if="availableElements.length > 1">
        <draggable class="dragArea list-group elementShop mt-4"
                   v-model="availableElements"
                   :group="{ name: 'builderElements', pull: 'clone', put: 'false' }"
                   :clone="cloneBasicElementToPageContent">
          <template v-for="(element, index) in availableElements">
            <v-col cols="12" :key="index">
              <v-card class="cursor-grab">
                <v-card-title v-if="!drawerMini">
                  <span class="body-2">
                    {{ element.contentConverted.displayName }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{on}">
                      <v-icon @click="openPopupSample(element.contentConverted)" v-on="on">
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>
                      Megtekintés
                    </span>
                  </v-tooltip>
                </v-card-title>
                <v-tooltip left v-else>
                  <template v-slot:activator="{on}">
                    <v-avatar v-on="on">
                      <span>
                        {{ element.contentConverted.displayName.slice(0,1) }}
                        {{ element.contentConverted.displayName.slice(element.contentConverted.displayName.indexOf(' ')+1,element.contentConverted.displayName.indexOf(' ')+2) }}
                        {{ element.contentConverted.displayName.slice(element.contentConverted.displayName.lastIndexOf(' ')+1,element.contentConverted.displayName.lastIndexOf(' ')+2) }}
                      </span>
                    </v-avatar>
                  </template>
                  <span>{{ element.contentConverted.displayName }}</span>
                </v-tooltip>
              </v-card>
            </v-col>
          </template>
        </draggable>
      </v-row>
    </v-navigation-drawer>
    <!-- PAGE SIDEBAR END -->
    <!-- PAGE CONTENT START -->
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <draggable class="dragArea list-group pageBuilder-liveEditor-wrapper"
                     :list="currentPage.content"
                     group="builderElements" animation="200">
            <template v-for="(item, index) in currentPage.content">
              <div class="pageBuilder-liveEditor-box mb-4" :key="index">
                <component :is="item.contentConverted.elementName"
                           :element-props.sync="item.contentConverted.elementProperties"
                           editor-mode
                           v-on:delete-element="deleteItem(index)"
                           v-on:save-element="saveItem(index)">
                </component>
              </div>
            </template>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
    <!-- PAGE CONTENT END -->
    <!-- BUILDER ELEMENT SAMPLE POPUP START -->
    <v-dialog v-model="popup.isVisible">
      <v-card>
        <v-card-title class="indigo">
          <span class="white--text">
            {{ popup.itemToShow.displayName }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="popup.isVisible = !popup.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <component :is="popup.itemToShow.elementName" :element-props="popup.itemToShow.elementProperties"></component>
      </v-card>
    </v-dialog>
    <!-- BUILDER ELEMENT SAMPLE POPUP END-->
    <!-- PAGE SETTING POPUP START -->
    <v-dialog v-model="popupSettings.isVisible" scrollable persistent transition="dialog-bottom-transition" max-width="640">
      <v-card tile>
        <v-card-title class="indigo">
            <span class="white--text">
              Oldal beállításai
            </span>
          <v-spacer></v-spacer>
          <v-icon @click="popupSettings.isVisible = !popupSettings.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="pageSettingsForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="pageSettings.webPageTranslation[0].name"
                              label="Oldal címe"
                              filled
                              :hint="`Közvetlen hivatkozás: ${origin}/${pageSettings.webPageTranslation[0].url}`"
                              persistent-hint
                              @change="createPageUrl">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <span class="text-body-1">
                  Szülő kiválasztása
                </span>
              </v-col>
              <v-col cols="12">
                <v-select v-model="pageParent"
                          :items="selectFieldOptions.parentPages"
                          item-text="code"
                          item-value="code"
                          return-object
                          label="Szülő oldal"
                          @change="createPageUrl"
                          filled>
                </v-select>
              </v-col>
              <template v-if="pageParent.code === 'Egyedi URL'">
                <v-col cols="12" class="text-body-1">
                  Egyedi URL megadása
                </v-col>
                <v-col cols="12">
                  <v-row class="mx-0">
                    <v-col class="pa-0">
                      <v-form v-model="uniqueURL">
                        <v-text-field v-model="currentPage.url"
                                      label="Közvetlen hivatkozás"
                                      :prefix="`${origin}/`"
                                      :disabled="!pageUrlEditable"
                                      :rules="inputRules.url"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-form>
                    </v-col>
                    <v-col cols="auto" v-if="!pageUrlEditable">
                      <v-btn icon color="primary">
                        <v-icon @click="pageUrlEditable = !pageUrlEditable">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="px-2" v-if="pageUrlEditable">
                      <v-btn icon color="success" :disabled="!uniqueURL">
                        <v-icon @click="createPageUrl">
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="px-2" v-if="pageUrlEditable">
                      <v-btn icon color="error">
                        <v-icon @click="restorePageUrl">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <!--<v-col cols="12">
                <v-text-field v-model="pageSettings.urlEN"
                              label="Közvetlen hivatkozás - Angol"
                              :hint="`${origin}/${pageSettings.urlEN}`"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>-->
              <!--<v-col cols="12">
                <v-text-field v-model="pageSettings.urlDE"
                              label="Közvetlen hivatkozás - Német"
                              :hint="`${origin}/${pageSettings.urlDE}`"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="popupSettings.isVisible = false" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="popupSettings.isVisible = false">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- PAGE SETTING POPUP END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'PageBuilderEditor',
  components: {
    draggable, LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    this.loadingAndResponseDialog.loadingText = 'LOADING_MESSAGES.LOADING_IN_PROGRESS';
    this.openLARDialog();
    await this.getAllPages();
    this.insertNoParentToSelectFieldOptions();
    if (this.$store.state.builderElements.basicElements.length === 0) {
      await this.callshowBasicElements();
      this.availableElements = await this.$store.dispatch('convertBasicElemetContent');
    } else {
      this.availableElements = this.$store.state.builderElements.basicElements;
    }
    if (this.$route.params.pageId === 'uj-oldal') {
      this.popupSettings.isVisible = true;
      this.pageSettings.status = 'NEW';
    } else {
      this.currentPage.content = await this.getPageInEditor();
    }
    if (this.$store.state.general.availablePages.length === 0) {
      await this.fillAvailablePages();
      await this.addActualJobsToAvailablePages();
    }
    // csak akkor ha nem HOME
    // await this.createPageUrl();
    this.closeLARDialog();
  },
  data() {
    return {
      origin: window.location.origin,
      currentPage: {
        content: [],
        url: '',
      },
      pageUrlEditable: false,
      availableElements: [],
      drawerMini: false,
      pageParent: {
        code: 'Nincs szülő',
      },
      popup: {
        isVisible: false,
        itemToShow: {},
      },
      popupSettings: {
        isVisible: false,
      },
      pageSettingsForm: false,
      pageSettings: {
        code: null,
        id: null,
        isDraft: null,
        status: 'NEW',
        webPageTranslation: [
          {
            id: 0,
            language: 'hu',
            name: 'Oldal címe',
            url: 'oldal-cime',
          },
        ],
      },
      done: '',
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'Bejelentkezés folyamatban.',
        successText: 'Sikeres bejelentkezés!',
        errorText: 'Sikertelen bejelentkezés!',
        successBtnText: 'Rendben',
        errorBtnText: 'Rendben',
      },
      selectFieldOptions: {
        parentPages: [],
      },
      inputRules: {
        url: [
          v => !!v || this.$t('hints.requiredField'),
          v => /^[a-z0-9-/\\]*$/.test(v) || this.$t('hints.urlRule'),
        ],
      },
      uniqueURL: false,
    };
  },
  methods: {
    async getAllPages() {
      if (!this.$store.state.pages.pages) {
        const response = await this.$store.dispatch('showPages');
      }
      this.selectFieldOptions.parentPages = this.$store.state.pages.pages;
    },
    insertNoParentToSelectFieldOptions() {
      this.selectFieldOptions.parentPages.unshift({
        code: 'Egyedi URL',
      });
      this.selectFieldOptions.parentPages.unshift({
        code: 'Nincs szülő',
      });
    },
    async callshowBasicElements() {
      const response = this.$store.dispatch('showBasicElements');
      return response;
    },
    async getPageInEditor() {
      const response = await this.$store.dispatch('showPage', this.$route.params.pageId);
      // console.log('getPageInEditor: ', response);
      if (response.status === 200) {
        const converted = JSON.parse(response.data.contentJson);
        const mapped = converted.map((item) => {
          // console.log('blllbe');
          return {
            contentConverted: item,
          };
        });
        this.pageSettings = {
          id: response.data.id,
          isVisible: true,
          isDraft: response.data.isDraft,
          status: response.data.isDraft === 1 ? 'DRAFT' : 'PUBLISHED',
          code: response.data.code,
          webPageTranslation: [
            {
              id: response.data.webPageTranslation[0].id,
              language: 'hu',
              url: response.data.webPageTranslation[0].url,
              name: response.data.webPageTranslation[0].name,
            },
          ],
        };
        return mapped;
      }
      return [];
    },
    cloneBasicElementToPageContent(basicElement) {
      // console.log('CLONE! - ', basicElement);
      return JSON.parse(JSON.stringify(basicElement));
    },
    deleteItem(index) {
      this.currentPage.content.splice(index, 1);
    },
    saveItem(index) {
      // console.log('save this item to db:', index);
    },
    openPopupSample(item) {
      this.popup.itemToShow = item;
      this.popup.isVisible = true;
    },
    async createPage(draftStatus = 1, updatePage = false) {
      this.openLARDialog();
      const now = new Date();
      const pageContentToConvert = [];
      this.currentPage.content.forEach((item) => {
        pageContentToConvert.push(item.contentConverted);
      });
      const convertedPageContent = JSON.stringify(pageContentToConvert);
      // console.log(convertedPageContent);
      const pageSettingsToSend = {
        id: updatePage ? this.$route.params.pageId : 0,
        code: this.pageSettings.status === 'NEW' ? this.pageSettings.webPageTranslation[0].name : this.pageSettings.code,
        contentJson: convertedPageContent,
        isDraft: draftStatus,
        lastPublishedDate: now.toISOString().substr(0, 10),
        webPageTranslation: this.pageSettings.webPageTranslation,
        webPageCategoryId: null,
      };
      let response = null;
      if (updatePage) {
        response = await this.$store.dispatch('updatePage', {
          settings: pageSettingsToSend,
          pageId: this.$route.params.pageId,
        });
      } else {
        response = await this.$store.dispatch('createPage', pageSettingsToSend);
      }
      if (response.status === 200) {
        if (updatePage) {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.PAGE_UPDATED';
        } else if (draftStatus === 0) {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.PAGE_PUBLISHED';
        } else {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.PAGE_DRAFT';
        }
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
      } else {
        if (updatePage) {
          this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.PAGE_UPDATE';
        } else if (draftStatus === 0) {
          this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.PAGE_PUBLISHED';
        } else {
          this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.PAGE_DRAFT';
        }
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    pagePreview() {
      this.$router.push(`/${this.pageSettings.webPageTranslation[0].url}`);
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      // this.$router.push({ name: 'pageBuilder-home' });
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    async createPageUrl() {
      if (this.pageParent.code !== 'Egyedi URL') {
        // console.log(1);
        this.currentPage.url = await this.$store.dispatch('convertToSlug', this.pageSettings.webPageTranslation[0].name);
      }
      if (this.pageParent.code === 'Nincs szülő') {
        // console.log(2);
        this.pageSettings.webPageTranslation[0].url = this.currentPage.url;
      } else if (this.pageParent.code === 'Egyedi URL') {
        // console.log(3);
        this.pageSettings.webPageTranslation[0].url = this.currentPage.url;
        this.pageUrlEditable = false;
      } else {
        // console.log(4);
        this.pageSettings.webPageTranslation[0].url = `${this.pageParent.webPageTranslation[0].url}/${this.currentPage.url}`;
      }
    },
    restorePageUrl() {
      this.currentPage.url = this.pageSettings.webPageTranslation[0].url;
      this.pageUrlEditable = false;
    },
    async fillAvailablePages() {
      this.$store.state.pages.pages.forEach((item) => {
        if (item.code === 'nincs szülő') {
          if (!item.isDraft) {
            this.$store.commit('setAvailablePages',
              {
                name: `${item.webPageTranslation[0].name}`,
                url: `/${item.webPageTranslation[0].url}`,
                group: 'Oldal',
              });
          }
        }
      });
    },
    async addActualJobsToAvailablePages() {
      const response = await this.$store.dispatch('showActualJobs');
      if (response.status === 200) {
        response.data.forEach((item) => {
          this.$store.commit('setAvailablePages',
            {
              name: `${item.translation.title}`,
              url: `/${item.translation.url}`,
              group: 'Állásajánlat',
            });
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
